<template>
  <div class="setting_form h-full">
    <div style="display: block; overflow-y: auto; height: calc(100vh - 19.2rem);">
      <div
        class="grid h-full w-full grid-cols-3"
        style="grid-template-rows: 1fr minmax(6rem,auto)"
      >
        <div class="flex flex-col justify-start items-start pt-4 px-8 border-r-[2px]">
          <p class="text font-bold !text-[#3C4549] text-[1rem] pb-3">
            General Settings
          </p>
          <FloatingLabelInput
            class="mb-3 w-full"
            :errorType="
            getWhiteLabelValidations.agency_name.name ||
            getWhiteLabelValidations.agency_name.nameLength
              ? 'danger'
              : ''
          "
            id="agency_name"
            v-model="getWhiteLabel.agency_name"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Agency Name"
            placeholder="Enter your agency name"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="getWhiteLabelValidations.agency_name.name"
                :message="whiteLabelMessage.name"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="getWhiteLabelValidations.agency_name.nameLength"
                :message="whiteLabelMessage.nameLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>
          <div class="border-t-[2px] w-full py-1.5"></div>
          <div class="flex flex-col !w-full ">
            <div class="flex flex-col gap-2  !w-full">
              <URLInputFloatingV2
                class="mb-3"
                id="url-of-image"
                :readonly="false"
                :disabled="false"
                v-model="getWhiteLabel.header_logo"
                :validations="getWhiteLabelValidations.header_logo"
                type="text"
                placeholder="Enter your agency name"
                label="App Image"
                customClass="h-[3.5rem] bg-[#F4F6FA]"
                :errorType="
              getWhiteLabelValidations.header_logo.url ||
              getWhiteLabelValidations.header_logo.urlLength ||
              getWhiteLabelValidations.header_logo.urlValid
                ? 'danger'
                : ''
            "
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="getWhiteLabelValidations.header_logo.url"
                    :message="URLMessages.url"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.header_logo.urlLength"
                    :message="URLMessages.urlLength"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.header_logo.urlValid"
                    :message="URLMessages.urlValid"
                  ></InputFieldMessage>
                </template>
              </URLInputFloatingV2>
              <ImageUploadV2
                customClass=" mb-3 w-full h-32"
                title="Header Logo"
                @click="uploadHeaderLogo($event)"
                :image="getWhiteLabel.header_logo"
              ></ImageUploadV2>
            </div>

            <div
              class="flex flex-col justify-start border-t border-t-[#F2F3F8] border-b border-b-[#F2F3F8] w-full"
            >
              <URLInputFloatingV2
                class="mb-3"
                id="url-of-favicon"
                :readonly="false"
                :disabled="false"
                v-model="getWhiteLabel.favicon"
                :validations="getWhiteLabelValidations.favicon"
                type="text"
                placeholder="Enter Privacy Policy URL"
                label="Favicon URL "
                customClass="h-[3.5rem] bg-[#F4F6FA]"
                :errorType="
              getWhiteLabelValidations.favicon.url ||
              getWhiteLabelValidations.favicon.urlLength ||
              getWhiteLabelValidations.favicon.urlValid
                ? 'danger'
                : ''
            "
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="getWhiteLabelValidations.favicon.url"
                    :message="URLMessages.url"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.favicon.urlLength"
                    :message="URLMessages.urlLength"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.favicon.urlValid"
                    :message="URLMessages.urlValid"
                  ></InputFieldMessage>
                </template>
              </URLInputFloatingV2>
              <ImageUploadV2
                title="Favicon"
                customClass="mb-3 h-32"
                @click="uploadFavicon($event)"
                :image="getWhiteLabel.favicon"
              ></ImageUploadV2>
            </div>
          </div>

          <FloatingLabelInput
            class="mb-3 w-full"
            :errorType="
            getWhiteLabelValidations.privacy_policy.url ||
            getWhiteLabelValidations.privacy_policy.urlLength ||
            getWhiteLabelValidations.privacy_policy.urlValid
              ? 'danger'
              : ''
          "
            id="privacy_url"
            v-model="getWhiteLabel.privacy_policy"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Privacy Policy URL"
            placeholder="Enter Privacy Policy URL"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="getWhiteLabelValidations.privacy_policy.url"
                :message="whiteLabelMessage.url"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="getWhiteLabelValidations.privacy_policy.urlValid"
                :message="whiteLabelMessage.urlValid"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="getWhiteLabelValidations.privacy_policy.urlLength"
                :message="whiteLabelMessage.urlLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            class="mb-3 w-full"
            :errorType="
            getWhiteLabelValidations.terms_of_service.url ||
            getWhiteLabelValidations.terms_of_service.urlLength ||
            getWhiteLabelValidations.terms_of_service.urlValid
              ? 'danger'
              : ''
          "
            id="term_of_service"
            v-model="getWhiteLabel.terms_of_service"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Terms of Service URL"
            placeholder="Enter Terms of Service URL"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="getWhiteLabelValidations.terms_of_service.url"
                :message="whiteLabelMessage.url"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="getWhiteLabelValidations.terms_of_service.urlValid"
                :message="whiteLabelMessage.urlValid"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="getWhiteLabelValidations.terms_of_service.urlLength"
                :message="whiteLabelMessage.urlLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>
        </div>
        <div class="flex flex-col justify-start items-start !pt-6 gap-4 px-8 ">
          <p class="text font-bold !text-[#3C4549] text-[1rem]">Login Page Settings</p>
          <div class="flex flex-col w-full"
               v-if="!getWhiteLabelLoaders.fetch">
            <div
              class="flex flex-col justify-start gap-4  w-full"

            >
              <URLInputFloatingV2
                id="url-of-login-logo"
                :readonly="false"
                :disabled="false"
                v-model="getWhiteLabel.login_logo"
                :validations="getWhiteLabelValidations.login_logo"
                type="text"
                placeholder="Enter Header Logo URL"
                label="Header Logo URL"
                customClass="h-[56px] bg-[#F4F6FA]"
                :errorType="
              getWhiteLabelValidations.login_logo.url ||
              getWhiteLabelValidations.login_logo.urlLength ||
              getWhiteLabelValidations.login_logo.urlValid
                ? 'danger'
                : ''
            "
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="getWhiteLabelValidations.login_logo.url"
                    :message="URLMessages.url"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.login_logo.urlLength"
                    :message="URLMessages.urlLength"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.login_logo.urlValid"
                    :message="URLMessages.urlValid"
                  ></InputFieldMessage>
                </template>
              </URLInputFloatingV2>
              <ImageUploadV2
                title="Login Logo"
                customClass="mb-3 h-32"
                @click="uploadLoginLogo($event)"
                :image="getWhiteLabel.login_logo"
              ></ImageUploadV2>
            </div>
            <div class="border-t-[2px] w-full"></div>

            <div
              class="flex flex-col justify-start w-full"
            >
              <URLInputFloatingV2
                class="my-3"
                id="url-of-app_loader"
                :readonly="false"
                :disabled="false"
                v-model="getWhiteLabel.app_loader"
                :validations="getWhiteLabelValidations.app_loader"
                type="text"
                placeholder="Enter the app loader image url"
                label="App Loader Image "
                customClass="h-[3.5rem] bg-[#F4F6FA]"
                :errorType="
              getWhiteLabelValidations.app_loader.url ||
              getWhiteLabelValidations.app_loader.urlLength ||
              getWhiteLabelValidations.app_loader.urlValid
                ? 'danger'
                : ''
            "
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="getWhiteLabelValidations.app_loader.url"
                    :message="URLMessages.url"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.app_loader.urlLength"
                    :message="URLMessages.urlLength"
                  ></InputFieldMessage>
                  <InputFieldMessage
                    v-else-if="getWhiteLabelValidations.app_loader.urlValid"
                    :message="URLMessages.urlValid"
                  ></InputFieldMessage>
                </template>
              </URLInputFloatingV2>
              <ImageUploadV2
                customClass="mb-4 h-32"
                title="App Loader"
                @click="uploadAppLoader($event)"
                :image="getWhiteLabel.app_loader"
              ></ImageUploadV2>
            </div>

          </div>
        </div>
        <div>

        </div>


      </div>
    </div>
    <div
      class="flex flex-col justify-between items-center !p-8  m-2 gap-4 rounded-[8px] bg-gray-700 !bg-[#F4F6FA] col-span-3">
      <div class="flex flex-row justify-center items-center gap-2 w-full">
        <Button
          @click.prevent="fetchWhiteLabel()"
          id="cancel-request-button"
          type="button"
          customClass="px-[0.75rem] mr-2 py-[0.75rem] text-sm leading-[0.875rem] !border-[1.5px] !border-[#3C4549] !rounded-[8px] "
        >
          <template v-slot:label>Discard Changes</template>
        </Button>
        <Button
          @click.prevent="applyWhiteLabelChanges()"
          id="resend-email-button"
          type="button"
          class="text-white bg-[#2560D7] hover:bg-[#1d4ed8]"
          customClass="px-[0.75rem] py-[0.75rem] text-sm !rounded-[8px] leading-[0.875rem] text-white"
        >
          <template v-slot:label v-if="!getWhiteLabelLoaders.store">Apply Changes</template>
          <template v-else v-slot:loader>
            <Loader></Loader>
          </template>
        </Button>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {whiteLabelMessage, URLMessages} from "@/common/attributes";

export default {
  name: "WhitelabelGeneralSettings",
  data() {
    return {
      whiteLabelMessage: whiteLabelMessage,
      URLMessages: URLMessages,
    };
  },
  computed: {
    ...mapGetters([
      "getWhiteLabel",
      "getWhiteLabelValidations",
      "getWhiteLabelLoaders",
    ]),
  },

  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
    ImageUploadV2: () => import("@/ui/ui-kit/v2/ImageUploadV2.vue"),
    URLInputFloatingV2: () => import("@/ui/ui-kit/v2/URLInputFloatingV2.vue"),
  },
  methods: {
    ...mapActions(["fetchWhiteLabel"]),
    uploadHeaderLogo(event) {
      this.getWhiteLabel.header_logo = event ? event : "";
    },
    uploadFavicon(event) {
      this.getWhiteLabel.favicon = event ? event : "";
    },
    uploadLoginLogo(event) {
      this.getWhiteLabel.login_logo = event ? event : "";
    },
    uploadAppLoader(event) {
      this.getWhiteLabel.app_loader = event ? event : "";
    },
  },
  watch: {
    "getWhiteLabel.agency_name"(value) {
      this.whiteLabelAgencyNameValidation();
    },
    "getWhiteLabel.privacy_policy"(value) {
      this.whiteLabelCommonUrlValidation("privacy_policy");
    },
    "getWhiteLabel.terms_of_service"(value) {
      this.whiteLabelCommonUrlValidation("terms_of_service");
    },
  },
};
</script>
<style scoped lang="less">
.setting_form {
  border-left: 1px solid #d0d6e5;

  .white_box {
    .box_inner {
      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }

          p {
            font-size: small;
          }
        }

        a {
          color: #1d8bfa;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
