var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"setting_form h-full"},[_c('div',{staticStyle:{"display":"block","overflow-y":"auto","height":"calc(100vh - 19.2rem)"}},[_c('div',{staticClass:"grid h-full w-full grid-cols-3",staticStyle:{"grid-template-rows":"1fr minmax(6rem,auto)"}},[_c('div',{staticClass:"flex flex-col justify-start items-start pt-4 px-8 border-r-[2px]"},[_c('p',{staticClass:"text font-bold !text-[#3C4549] text-[1rem] pb-3"},[_vm._v("\n          General Settings\n        ")]),_c('FloatingLabelInput',{staticClass:"mb-3 w-full",attrs:{"errorType":_vm.getWhiteLabelValidations.agency_name.name ||
          _vm.getWhiteLabelValidations.agency_name.nameLength
            ? 'danger'
            : '',"id":"agency_name","customClass":"h-[56px] bg-[#F4F6FA]","type":"text","label":"Agency Name","placeholder":"Enter your agency name"},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.agency_name.name)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.name}}):(_vm.getWhiteLabelValidations.agency_name.nameLength)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.nameLength}}):_vm._e()]},proxy:true}]),model:{value:(_vm.getWhiteLabel.agency_name),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "agency_name", $$v)},expression:"getWhiteLabel.agency_name"}}),_c('div',{staticClass:"border-t-[2px] w-full py-1.5"}),_c('div',{staticClass:"flex flex-col !w-full "},[_c('div',{staticClass:"flex flex-col gap-2  !w-full"},[_c('URLInputFloatingV2',{staticClass:"mb-3",attrs:{"id":"url-of-image","readonly":false,"disabled":false,"validations":_vm.getWhiteLabelValidations.header_logo,"type":"text","placeholder":"Enter your agency name","label":"App Image","customClass":"h-[3.5rem] bg-[#F4F6FA]","errorType":_vm.getWhiteLabelValidations.header_logo.url ||
            _vm.getWhiteLabelValidations.header_logo.urlLength ||
            _vm.getWhiteLabelValidations.header_logo.urlValid
              ? 'danger'
              : ''},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.header_logo.url)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.url}}):(_vm.getWhiteLabelValidations.header_logo.urlLength)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlLength}}):(_vm.getWhiteLabelValidations.header_logo.urlValid)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlValid}}):_vm._e()]},proxy:true}]),model:{value:(_vm.getWhiteLabel.header_logo),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "header_logo", $$v)},expression:"getWhiteLabel.header_logo"}}),_c('ImageUploadV2',{attrs:{"customClass":" mb-3 w-full h-32","title":"Header Logo","image":_vm.getWhiteLabel.header_logo},on:{"click":function($event){return _vm.uploadHeaderLogo($event)}}})],1),_c('div',{staticClass:"flex flex-col justify-start border-t border-t-[#F2F3F8] border-b border-b-[#F2F3F8] w-full"},[_c('URLInputFloatingV2',{staticClass:"mb-3",attrs:{"id":"url-of-favicon","readonly":false,"disabled":false,"validations":_vm.getWhiteLabelValidations.favicon,"type":"text","placeholder":"Enter Privacy Policy URL","label":"Favicon URL ","customClass":"h-[3.5rem] bg-[#F4F6FA]","errorType":_vm.getWhiteLabelValidations.favicon.url ||
            _vm.getWhiteLabelValidations.favicon.urlLength ||
            _vm.getWhiteLabelValidations.favicon.urlValid
              ? 'danger'
              : ''},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.favicon.url)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.url}}):(_vm.getWhiteLabelValidations.favicon.urlLength)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlLength}}):(_vm.getWhiteLabelValidations.favicon.urlValid)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlValid}}):_vm._e()]},proxy:true}]),model:{value:(_vm.getWhiteLabel.favicon),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "favicon", $$v)},expression:"getWhiteLabel.favicon"}}),_c('ImageUploadV2',{attrs:{"title":"Favicon","customClass":"mb-3 h-32","image":_vm.getWhiteLabel.favicon},on:{"click":function($event){return _vm.uploadFavicon($event)}}})],1)]),_c('FloatingLabelInput',{staticClass:"mb-3 w-full",attrs:{"errorType":_vm.getWhiteLabelValidations.privacy_policy.url ||
          _vm.getWhiteLabelValidations.privacy_policy.urlLength ||
          _vm.getWhiteLabelValidations.privacy_policy.urlValid
            ? 'danger'
            : '',"id":"privacy_url","customClass":"h-[56px] bg-[#F4F6FA]","type":"text","label":"Privacy Policy URL","placeholder":"Enter Privacy Policy URL"},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.privacy_policy.url)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.url}}):(_vm.getWhiteLabelValidations.privacy_policy.urlValid)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.urlValid}}):(_vm.getWhiteLabelValidations.privacy_policy.urlLength)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.urlLength}}):_vm._e()]},proxy:true}]),model:{value:(_vm.getWhiteLabel.privacy_policy),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "privacy_policy", $$v)},expression:"getWhiteLabel.privacy_policy"}}),_c('FloatingLabelInput',{staticClass:"mb-3 w-full",attrs:{"errorType":_vm.getWhiteLabelValidations.terms_of_service.url ||
          _vm.getWhiteLabelValidations.terms_of_service.urlLength ||
          _vm.getWhiteLabelValidations.terms_of_service.urlValid
            ? 'danger'
            : '',"id":"term_of_service","customClass":"h-[56px] bg-[#F4F6FA]","type":"text","label":"Terms of Service URL","placeholder":"Enter Terms of Service URL"},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.terms_of_service.url)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.url}}):(_vm.getWhiteLabelValidations.terms_of_service.urlValid)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.urlValid}}):(_vm.getWhiteLabelValidations.terms_of_service.urlLength)?_c('InputFieldMessage',{attrs:{"message":_vm.whiteLabelMessage.urlLength}}):_vm._e()]},proxy:true}]),model:{value:(_vm.getWhiteLabel.terms_of_service),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "terms_of_service", $$v)},expression:"getWhiteLabel.terms_of_service"}})],1),_c('div',{staticClass:"flex flex-col justify-start items-start !pt-6 gap-4 px-8 "},[_c('p',{staticClass:"text font-bold !text-[#3C4549] text-[1rem]"},[_vm._v("Login Page Settings")]),(!_vm.getWhiteLabelLoaders.fetch)?_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-col justify-start gap-4  w-full"},[_c('URLInputFloatingV2',{attrs:{"id":"url-of-login-logo","readonly":false,"disabled":false,"validations":_vm.getWhiteLabelValidations.login_logo,"type":"text","placeholder":"Enter Header Logo URL","label":"Header Logo URL","customClass":"h-[56px] bg-[#F4F6FA]","errorType":_vm.getWhiteLabelValidations.login_logo.url ||
            _vm.getWhiteLabelValidations.login_logo.urlLength ||
            _vm.getWhiteLabelValidations.login_logo.urlValid
              ? 'danger'
              : ''},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.login_logo.url)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.url}}):(_vm.getWhiteLabelValidations.login_logo.urlLength)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlLength}}):(_vm.getWhiteLabelValidations.login_logo.urlValid)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlValid}}):_vm._e()]},proxy:true}],null,false,1730253158),model:{value:(_vm.getWhiteLabel.login_logo),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "login_logo", $$v)},expression:"getWhiteLabel.login_logo"}}),_c('ImageUploadV2',{attrs:{"title":"Login Logo","customClass":"mb-3 h-32","image":_vm.getWhiteLabel.login_logo},on:{"click":function($event){return _vm.uploadLoginLogo($event)}}})],1),_c('div',{staticClass:"border-t-[2px] w-full"}),_c('div',{staticClass:"flex flex-col justify-start w-full"},[_c('URLInputFloatingV2',{staticClass:"my-3",attrs:{"id":"url-of-app_loader","readonly":false,"disabled":false,"validations":_vm.getWhiteLabelValidations.app_loader,"type":"text","placeholder":"Enter the app loader image url","label":"App Loader Image ","customClass":"h-[3.5rem] bg-[#F4F6FA]","errorType":_vm.getWhiteLabelValidations.app_loader.url ||
            _vm.getWhiteLabelValidations.app_loader.urlLength ||
            _vm.getWhiteLabelValidations.app_loader.urlValid
              ? 'danger'
              : ''},scopedSlots:_vm._u([{key:"error_message",fn:function(){return [(_vm.getWhiteLabelValidations.app_loader.url)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.url}}):(_vm.getWhiteLabelValidations.app_loader.urlLength)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlLength}}):(_vm.getWhiteLabelValidations.app_loader.urlValid)?_c('InputFieldMessage',{attrs:{"message":_vm.URLMessages.urlValid}}):_vm._e()]},proxy:true}],null,false,4042169438),model:{value:(_vm.getWhiteLabel.app_loader),callback:function ($$v) {_vm.$set(_vm.getWhiteLabel, "app_loader", $$v)},expression:"getWhiteLabel.app_loader"}}),_c('ImageUploadV2',{attrs:{"customClass":"mb-4 h-32","title":"App Loader","image":_vm.getWhiteLabel.app_loader},on:{"click":function($event){return _vm.uploadAppLoader($event)}}})],1)]):_vm._e()]),_c('div')])]),_c('div',{staticClass:"flex flex-col justify-between items-center !p-8  m-2 gap-4 rounded-[8px] bg-gray-700 !bg-[#F4F6FA] col-span-3"},[_c('div',{staticClass:"flex flex-row justify-center items-center gap-2 w-full"},[_c('Button',{attrs:{"id":"cancel-request-button","type":"button","customClass":"px-[0.75rem] mr-2 py-[0.75rem] text-sm leading-[0.875rem] !border-[1.5px] !border-[#3C4549] !rounded-[8px] "},on:{"click":function($event){$event.preventDefault();return _vm.fetchWhiteLabel()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Discard Changes")]},proxy:true}])}),_c('Button',{staticClass:"text-white bg-[#2560D7] hover:bg-[#1d4ed8]",attrs:{"id":"resend-email-button","type":"button","customClass":"px-[0.75rem] py-[0.75rem] text-sm !rounded-[8px] leading-[0.875rem] text-white"},on:{"click":function($event){$event.preventDefault();return _vm.applyWhiteLabelChanges()}},scopedSlots:_vm._u([(!_vm.getWhiteLabelLoaders.store)?{key:"label",fn:function(){return [_vm._v("Apply Changes")]},proxy:true}:{key:"loader",fn:function(){return [_c('Loader')]},proxy:true}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }